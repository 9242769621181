import axios from "axios";
import Router from 'next/router';

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  withCredentials: true
});

axiosInstance.interceptors.request.use((request) => {
  request.headers["current-url"] = sessionStorage.getItem("current-url");
  if ("/users/login" == request.url && localStorage.getItem("userInfo")) {
    localStorage.setItem("LOGOUT_EVENT", "");
    localStorage.removeItem('LOGOUT_EVENT');
  }
  if (sessionStorage.getItem("userInfo")) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    request.headers.uid = userInfo.uid;
  }
  return request;
});

axiosInstance.interceptors.response.use((response) => {
  return response;
}, error => {
  if (error.response.status === 401) {
    if (error.response.headers["current-url"])
    {
      Router.push("/sessionexpired?redirect="+error.response.headers["current-url"]);
    }
    else {
      Router.push("/sessionexpired");
    }     
    console.error(error);
    return Promise.resolve(error);
  }
  else if (error.response.status === 403) {
    Router.push("/403", "/home/feed");
    console.error(error);
    return Promise.resolve(error);
  }
  else {
    console.error(error);
    // handle the response error, sending the error back to calling method and handled there.
    return Promise.reject(error);
  }
});

module.exports = axiosInstance;