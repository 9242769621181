import React, { useContext, useState } from 'react';
import Router, { useRouter } from 'next/router';

import Link from 'next/link';
import { Alert, Button, Col, Card, Divider, Form, Input, Row, Typography } from 'antd';

import md5 from 'crypto-js/md5';
import aes from 'crypto-js/aes';
import sha256 from 'crypto-js/sha256';

import axios from '~/components/axiosInstance';
import { UserInfoContext } from '~/components/UserInfoContext';

const { Title } = Typography;

export default function LoginComponent() {

	const router = useRouter();

	const context = useContext(UserInfoContext);

	const { redirect } = router.query;

	const [invalidCredentials, setInvalidCredentials] = useState(false);

	const [isUserBlocked, setUserBlocked] = useState(false);

	const [loading, setLoading] = useState(false);

	function login(values) {
		setInvalidCredentials(false);
		setUserBlocked(false);
		let salt = md5(values.emailid).toString();
		axios.post("/users/login", {
			"emailid": values.emailid,
			"password": aes.encrypt(sha256(values.password).toString(), salt).toString()
		}).then((response) => {
			if (200 === response.status) {
				if ("SUCCESS" == response.data.status && "AUTHENTICATED" == response.data.message) {
					context.setUserInfo(response.data.user);
					context.updateUserInfo('isAuthorized', true);
					if (redirect) {
						Router.push(redirect);
					}
					else {
						Router.push('/home/feed');
					}
				}
				if ('FAILURE' == response.data.status && "INVALID_CREDENTIALS" == response.data.message) {
					setInvalidCredentials(true);
					setLoading(false);
				}
				if ('FAILURE' == response.data.status && "USER_BLOCKED" == response.data.message) {
					setUserBlocked(true);
					setLoading(false);
				}
			}
		})
			.catch((error) => {
				setLoading(false);
				console.error(error);
				Router.push("/500", router.asPath);
			});
	}

	return (
		<Card bordered={false} style={{ background: 'transparent' }}>
			<Row align="middle" justify="center">
				<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
					<Card bordered={false} title="Login to Fastrbid">
						<Form autoComplete="off" layout="vertical" noValidate onFinish={(values) => setLoading(true, login(values))}>
							<Row align="middle" justify="center">
								<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="left">
									{invalidCredentials &&
										<Alert message="Invalid credential." type="error" showIcon closable afterClose={() => setInvalidCredentials(false)} />
									}
									{isUserBlocked &&
										<Alert message="User blocked by administrator." type="error" showIcon closable afterClose={() => setInvalidCredentials(false)} />
									}
								</Col>
							</Row>
							<Row align="middle" justify="center">
								<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="left">
									<Form.Item name="emailid" label="Email Id" hasFeedback validateTrigger={["onChange", "onBlur"]} rules={[
										{
											type: 'email', message: 'Invalid.',
										},
										{
											whitespace: true, message: 'Invalid.',
										},
										{
											required: true, message: 'Required.',
										},
										{
											max: 320, message: 'Too long.'
										},
									]} validateFirst normalize={(input) => input.toLowerCase()}>
										<Input size="large" placeholder="Email Id" autoFocus autoComplete="off" />
									</Form.Item>
								</Col>
							</Row>
							<Row align="middle" justify="center">
								<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="left">
									<Form.Item name="password" label="Password" hasFeedback validateTrigger={["onChange", "onBlur"]}
										rules={[
											{
												pattern: /^\S+$/, message: 'Spaces not Allowed.'
											},
											{
												min: 8, message: 'Too short.'
											},
											{
												max: 25, message: 'Too long.'
											},
											{
												required: true, message: 'Required.'
											},
										]} validateFirst>
										<Input.Password size="large" placeholder="Password" autoComplete="off" />
									</Form.Item>
								</Col>
							</Row>
							<Row align="middle" justify="center">
								<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
									<Form.Item>
										<Button size="large" htmlType="submit" shape="round" type="primary" block loading={loading} disabled={loading}>Login</Button>
									</Form.Item>
								</Col>
							</Row>
							<Row align="middle" justify="center">
								<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
									<Title level={5}><Link href="/forgotpassword" passHref><a target="_self">Forgot Password</a></Link></Title>
								</Col>
							</Row>
							<Divider>OR</Divider>
							<Row align="middle" justify="center">
								<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
									<Title level={5}>Don&apos;t have an account? <Link href="/register" passHref><a target="_self">Register</a></Link></Title>
								</Col>
							</Row>
						</Form>
					</Card>
				</Col>
			</Row>
		</Card>
	)

}