import React from 'react';

import { Card, Col, Row, Layout } from 'antd';

const { Content } = Layout;

export default function UnauthorizedRightSideView(props) {

	return (
		<Row justify="center" style={{ backgroundColor: '#007aff', 'minHeight': '100vh' }}>
			<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
				<Content style={{ backgroundColor: 'transparent' }}>
					<Row align="middle" justify="center">
						<Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={16} align="center">
							{props.children}
						</Col>
					</Row>
				</Content>
			</Col>
		</Row>
	);

}