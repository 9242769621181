import React from 'react';

import { Affix, Col, Row, Layout } from 'antd';

import UnauthorizedPageHeader from '~/components/wrapper/unauthorizedpageheader';
import UnauthorizedPageFooter from '~/components/wrapper/unauthorizedpagefooter';

const { Content } = Layout;

export default function UnauthorizedLeftSideView(props) {

    return (
        <Affix>
            <Row justify="center" style={{ 'minHeight': '100vh', backgroundColor: 'transparent' }}>
                <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24} align="center">
                    <Row align="middle" justify="center">
                        <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24} align="center">
                            <UnauthorizedPageHeader />
                        </Col>
                    </Row>
                    <Row align="middle" justify="center">
                        <Col xs={0} sm={0} md={16} lg={16} xl={16} xxl={16} align="center">
                            <Content>
                                {props.children}
                            </Content>
                        </Col>
                    </Row>
                    <Row align="middle" justify="center">
                        <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24} align="center">
                            <UnauthorizedPageFooter />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Affix>
    );

}